import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { Auth } from 'aws-amplify';

import DocumentTitle from 'react-document-title';
import {
	Alert,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	Input,
	Label,
	Row,
	Progress,
	UncontrolledTooltip
} from 'reactstrap';
import { Line, Chart } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import ReactGA from 'react-ga4';

import {
	fetchLastUploadDate,
	resetLastUploadDate,
	fetchSteelSmelterCO2Emissions,
	resetSteelSmelterCO2Emissions,
	fetchCsvSteelCO2EmissionsData,
	fetchLocations,
	resetLocations,
	fetchGroups,
	resetGroups,
	fetchWorldSteelData
} from 'actions';
import {
	colourPalette,
	eChartOptions,
	ewChartOptions,
	emChartOptions,
	eyChartOptions,
	genChartStyle,
	plugins
} from './chartConfig';
import { Crumbs } from 'containers';
import { DateRangePopover, LoaderButton } from 'components';
import {
	mergeArrays,
	zipArrays,
	mergeSubArraysBasedOnKey,
	groupByKey,
	getUniqueObjects
} from '../../../utils';
import MultiSelect from 'components/multiselect/MultiSelect';
import './Smelters.scss';

const config = {
	dateRangeDayLimit: 10,
	apiDateFormat: 'YYYYMMDD',
	dspDateFormat: 'YYYY-MM-DD',
	globalRegion: {
		name: 'CO2 Emissions',
		colour: '#FFA500',
		title: 'Global'
	},
	combinedRegion: {
		name: 'Combined CO2 Emissions',
		colour: '#FFA500'
	},
	multipleSelectionLimit: 5
};

class SteelSmeltersCO2Emissions extends Component {
	constructor(props) {
		super(props);
		this.csvFullDownloadLink = React.createRef();
		this.state = {
			groupType: 'region',
			global: true,
			selectedGrouping: {},
			selected: 'regions',
			compareCombine: '',
			collapse: 'daily',
			isCollapsing: 'false',
			dateMin: moment.utc('2016-03-01'),
			dateMax: moment.utc(),
			dateFrom: moment.utc(),
			dateTo: moment.utc(),
			isInitDateRange: false,
			shortDateRange: false,
			showDataWarning: false,
			canDownload: true,
			csvFull: {
				csvHeaders: [],
				csvData: []
			},
			csvAllPlantTypes: false,
			isDownloadingAll: false,
			plantTypes: [],
			selectedPlantType: '',
			dataByPlant: { combined: '', bfbof: '', eaf: '' }
		};
	}

	async componentDidMount() {
		const authUser = await Auth.currentAuthenticatedUser();
		this.userSub = authUser.attributes['sub'] || '';
		plugins.forEach((plugin) => {
			//register plugin if it is not already registered
			if (!Chart.pluginService._plugins.includes(plugin)) {
				Chart.pluginService.register(plugin);
			}
		});

		this.props.fetchLocations({ commodity: 'steel' });
		this.props.fetchGroups({ commodity: 'steel' });
		this.props.fetchLastUploadDate({ commodity: 'steel' });
	}

	componentDidUpdate(
		{
			lastUploadDate: prevLastUploadDate,
			steelSmelterCO2Emissions: prevSteelSmelterData,
			locations: { regions: prevRegions }
		},
		{
			isInitDateRange: prevIsInitDateRange,
			dateFrom: prevDateFrom,
			dateTo: prevDateTo,
			selected: prevSelected,
			selectedGrouping: prevSelectedGrouping,
			global: prevGlobal,
			selectedPlantType: prevSelectedPlantType,
			collapse: prevCollapse,
			isDownloadingAll: prevIsDownloadingAll,
			csvAllPlantTypes: prevCsvAllPlantTypes
		}
	) {
		const {
			lastUploadDate,
			steelSmelterCO2Emissions,
			locations: { regions, countries }
		} = this.props;
		const {
			isInitDateRange,
			dateFrom,
			dateTo,
			selected,
			selectedGrouping,
			global,
			selectedPlantType,
			dataByPlant,
			plantTypes,
			collapse,
			isDownloadingAll,
			csvAllPlantTypes
		} = this.state;

		//fetchSteelNewestDate requested in ComponentDidMount(), ensure newestDate is received
		if (lastUploadDate === '') {
			return;
		}

		//Date setting
		if (lastUploadDate !== prevLastUploadDate) {
			this.updateDateHandling({ lastUploadDate });
		}

		if (regions.length === 1 && regions.length !== prevRegions.length) {
			this.setState(
				{
					groupType: 'country',
					selected: 'countries',
					global: false
				},
				() => this.handleMultipleSelections(countries)
			);
			return;
		}

		//once date range is set, fetch data
		if (
			(isInitDateRange !== prevIsInitDateRange && regions.length) ||
			(isInitDateRange && regions.length !== prevRegions.length)
		) {
			this.filterData();
		}

		if (
			global !== prevGlobal ||
			selectedGrouping[selected]?.length !==
				prevSelectedGrouping[selected]?.length ||
			selectedPlantType !== prevSelectedPlantType ||
			csvAllPlantTypes !== prevCsvAllPlantTypes
		) {
			this.resetFullCsvData();
		}

		if (isDownloadingAll && !prevIsDownloadingAll) {
			toastr.info('This might take a few minutes.');
		}

		if (
			(isInitDateRange === prevIsInitDateRange &&
				(prevDateFrom !== dateFrom || prevDateTo !== dateTo)) ||
			selected !== prevSelected ||
			selectedGrouping[selected]?.length !==
				prevSelectedGrouping[selected]?.length ||
			((global || selectedGrouping[selected]?.length === 1) &&
				selectedPlantType !== prevSelectedPlantType &&
				selectedPlantType &&
				!dataByPlant[selectedPlantType]) ||
			(selectedGrouping[selected]?.length >= 1 &&
				selectedPlantType !== prevSelectedPlantType) ||
			global !== prevGlobal ||
			collapse !== prevCollapse
		) {
			this.filterData();
		}

		if (
			Object.keys(steelSmelterCO2Emissions).length &&
			!Object.keys(prevSteelSmelterData).length &&
			(global || selectedGrouping[selected]?.length)
		) {
			this.setState({ isCollapsing: false });
			if (
				selectedGrouping[selected]?.length >= 1 &&
				plantTypes.length &&
				selectedGrouping[selected].map(({ name }) => name).length >
					steelSmelterCO2Emissions?.singleIdx.map(({ name }) => name).length
			) {
				toastr.warning(
					'CO2 Emissions',
					`No data for ${
						selectedPlantType === 'eaf' ? 'EAF' : 'BF/BOF'
					} in ${selectedGrouping[selected]
						.map(({ name }) => name)
						.filter(
							(n) =>
								!steelSmelterCO2Emissions?.singleIdx
									.map(({ name }) => name)
									.includes(n)
						)
						.join(', ')}
				`
				);
			}
			if (selectedGrouping[selected]?.length > 1) {
				return;
			}
			selectedPlantType
				? global
					? this.setState({
							dataByPlant: {
								...dataByPlant,
								[selectedPlantType]: steelSmelterCO2Emissions.combinedIdx
							}
					  })
					: selectedGrouping[selected]?.length === 1 &&
					  this.setState({
							dataByPlant: {
								...dataByPlant,
								[selectedPlantType]:
									steelSmelterCO2Emissions.singleIdx[0]?.data || []
							}
					  })
				: global
				? this.setState({
						dataByPlant: {
							...dataByPlant,
							combined: steelSmelterCO2Emissions.combinedIdx
						}
				  })
				: selectedGrouping[selected]?.length === 1 &&
				  this.setState({
						dataByPlant: {
							...dataByPlant,
							combined: steelSmelterCO2Emissions.singleIdx[0]?.data || []
						},
						selectedPlantType:
							!plantTypes.length ||
							plantTypes.every((pt) => dataByPlant[pt]) === true
								? selectedPlantType
								: plantTypes.find((pt) => dataByPlant[pt] === '')
				  });
		}
	}

	componentWillUnmount() {
		this.props.resetSteelSmelterCO2Emissions();
		this.props.resetLocations();
		this.props.resetGroups();
		this.props.resetLastUploadDate();
	}

	async updateDateHandling({ lastUploadDate }) {
		const maxDate = moment.utc(lastUploadDate);

		let downloadState = {};

		//for enterprise user, savant-premium-plus, savant-premium
		let dateState = {
			dateFrom: maxDate.clone().subtract(1, 'y').subtract(1, 'd'),
			dateTo: maxDate.clone().subtract(1, 'd'),
			dateMax: maxDate.clone().subtract(1, 'd')
		};

		this.setState({
			...dateState,
			...downloadState,
			isInitDateRange: true
		});
	}

	// Radio button click for combine/compare
	async onRadioBtnClick(rSelected) {
		this.setState({ ...rSelected });
	}

	//reset csv data for the full date range
	resetFullCsvData() {
		this.setState({ csvFull: { csvHeaders: [], csvData: [] } });
	}
	// Global button click
	async onGlobalBtnClick() {
		const { global, selected, selectedGrouping } = this.state;

		let newState = {};
		//reset regions when switching to global, but only for current view
		if (!global) {
			const newSelectedGrouping = { ...selectedGrouping };
			newSelectedGrouping[selected] = [];
			newState = { ...newState, selectedGrouping: newSelectedGrouping };
		}

		//toggle global
		newState = {
			...newState,
			global: !global,
			selectedPlantType: '',
			plantTypes: [],
			dataByPlant: { combined: '', bfbof: '', eaf: '' },
			compareCombine: ''
		};

		this.setState(newState);
	}

	// region buttons
	async onRegionBtnClick({ name, val }) {
		const { selected, selectedGrouping, selectedPlantType, plantTypes } =
			this.state;

		//existing regions
		const selectedIds = selectedGrouping[selected]?.map(({ id }) => id) || [];
		const oldSelectedCount = selectedIds.length;

		let selectedRegions = [];

		if (selectedIds.includes(val)) {
			selectedRegions = selectedGrouping[selected].filter(
				({ id }) => id !== val
			);
		} else {
			const selectedColours =
				selectedGrouping[selected]?.map(({ colour }) => colour) || [];
			const colour =
				selectedColours.length === 0
					? colourPalette[0]
					: colourPalette.filter((c) => !selectedColours.includes(c))[0];
			selectedRegions =
				selectedIds.length === 0
					? [{ id: val, name, colour }]
					: [...selectedGrouping[selected], { id: val, name, colour }];
		}
		const newSelectedGrouping = {
			...selectedGrouping,
			[selected]: selectedRegions
		};
		const newSelectedCount = newSelectedGrouping[selected].length;

		const global = newSelectedCount ? false : true;

		let newState = {
			selectedGrouping: newSelectedGrouping,
			global: global,
			dataByPlant: { combined: '', bfbof: '', eaf: '' },
			plantTypes: newSelectedCount && plantTypes.length <= 1 ? plantTypes : [],
			selectedPlantType:
				newSelectedCount && plantTypes.length <= 1 ? selectedPlantType : ''
		};

		if (newSelectedCount <= 1) {
			newState = {
				...newState,
				compareCombine: ''
			};
		} else if (oldSelectedCount <= 1) {
			//regions count increased
			newState = { ...newState, compareCombine: 'combine' };
		}
		this.setState(newState);
	}

	async onPlantBtnClick({ key }) {
		const {
			plantTypes,
			dataByPlant,
			selectedGrouping,
			selected,
			compareCombine
		} = this.state;

		if (plantTypes.includes(key)) {
			const newPlantTypes = plantTypes.filter((pt) => pt !== key);
			this.setState({
				plantTypes: newPlantTypes,
				selectedPlantType: newPlantTypes.length ? newPlantTypes[0] : '',
				compareCombine:
					selectedGrouping[selected]?.length > 1 ? compareCombine : ''
			});
		} else if (selectedGrouping[selected]?.map(({ id }) => id).length > 1) {
			this.setState({
				plantTypes: [key],
				selectedPlantType: key
			});
		} else {
			const newPlantTypes = [...plantTypes, key];

			this.setState({
				compareCombine: newPlantTypes.length <= 1 ? '' : 'combine',
				selectedPlantType:
					newPlantTypes.length > 1 && dataByPlant.combined?.length === 0
						? ''
						: key,
				plantTypes: newPlantTypes
			});
		}
	}

	handleMultipleSelections = async (vals) => {
		const { selected, selectedGrouping, selectedPlantType, plantTypes } =
			this.state;
		const oldSelectedIds =
			selectedGrouping[selected]?.map(({ id }) => id) || [];
		const newSelectedIds = vals.map(({ id }) => id);

		let newSelectedOptions = [];
		//removed
		if (oldSelectedIds.length > newSelectedIds.length) {
			newSelectedOptions = selectedGrouping[selected].filter(({ id }) =>
				newSelectedIds.includes(id)
			);
		} else {
			//one option added
			const newAdded = vals.filter(({ id }) => !oldSelectedIds.includes(id));
			const { id, name } = [...newAdded][0];
			const selectedColours =
				selectedGrouping[selected]?.map(({ colour }) => colour) || [];
			const colour =
				selectedColours.length === 0
					? colourPalette[0]
					: colourPalette.filter((c) => !selectedColours.includes(c))[0];
			newSelectedOptions =
				oldSelectedIds.length === 0
					? [{ id, name, colour }]
					: [...selectedGrouping[selected], { id, name, colour }];
		}

		const newSelectedGrouping = {
			...selectedGrouping,
			[selected]: newSelectedOptions
		};
		const newSelectedCount = newSelectedGrouping[selected].length;
		const global = newSelectedCount ? false : true;

		let newState = {
			selectedGrouping: newSelectedGrouping,
			global: global,
			dataByPlant: { combined: '', bfbof: '', eaf: '' },
			plantTypes: newSelectedCount && plantTypes.length <= 1 ? plantTypes : [],
			selectedPlantType:
				newSelectedCount && plantTypes.length <= 1 ? selectedPlantType : ''
			// compareCombine: selectedIds.length <= 1 ? '' : compareCombine
		};
		if (newSelectedCount <= 1) {
			newState = { ...newState, compareCombine: '' };
		} else if (oldSelectedIds.length <= 1) {
			//options count increased
			newState = { ...newState, compareCombine: 'combine' };
		}
		this.setState(newState);
	};

	// Region toggling for the select all / none button
	async onToggleAllRegions() {
		const { selectedGrouping, compareCombine, plantTypes, selectedPlantType } =
			this.state;
		const { locations } = this.props;
		const { regions } = locations;

		if (!selectedGrouping.hasOwnProperty(['regions'])) {
			selectedGrouping['regions'] = [];
		}

		let selectedRegions = [];
		if (selectedGrouping['regions'].length < regions.length) {
			const newRegions = regions.filter(
				({ id }) =>
					!selectedGrouping['regions'].map(({ id }) => id).includes(id)
			);
			const selectedColours =
				selectedGrouping['regions']?.map(({ colour }) => colour) || [];
			const newColours = colourPalette.filter(
				(c) => !selectedColours.includes(c)
			);
			newRegions.forEach((r, i) => (r.colour = newColours[i]));
			selectedRegions = [...selectedGrouping['regions'], ...newRegions];
		}
		const global = selectedRegions.length === 0;
		const newSelectedGrouping = {
			...selectedGrouping,
			regions: selectedRegions
		};
		const newCompareCombine =
			selectedGrouping['regions'].length > 1 ? compareCombine : 'combine';

		await this.setState({
			selectedGrouping: newSelectedGrouping,
			compareCombine: newSelectedGrouping['regions'].length
				? newCompareCombine
				: '',
			dataByPlant: { combined: '', bfbof: '', eaf: '' },
			plantTypes:
				selectedRegions.length && plantTypes.length <= 1 ? plantTypes : [],
			selectedPlantType:
				selectedRegions.length && plantTypes.length <= 1
					? selectedPlantType
					: '',
			global
		});
		// this.filterData();
	}

	// Handle date range selection event
	async onDateRangeSelect({ startDate, endDate, selectedGrouping, selected }) {
		//startDate, endDate - moment objects
		const newPlantState =
			global || selectedGrouping[selected]?.length === 1
				? {
						plantTypes: [],
						selectedPlantType: '',
						dataByPlant: { combined: '', bfbof: '', eaf: '' },
						compareCombine: ''
				  }
				: {};

		this.setState({
			dateFrom: startDate,
			dateTo: endDate,
			shortDateRange:
				endDate.diff(startDate, 'days') < config.dateRangeDayLimit,
			...newPlantState
		});

		// !this.state.shortDateRange && this.filterData();
	}

	onInputSelect = async ({ target: { value, name } }) => {
		const { selectedGrouping } = this.state;
		if (name === 'groupType') {
			let filtering = '';

			if (value === 'region') {
				filtering = 'regions';
			} else if (value === 'country') {
				filtering = 'countries';
			} else {
				filtering = value;
			}

			const global = selectedGrouping[filtering]?.length ? false : true;
			this.setState({
				plantTypes: [],
				selectedPlantType: '',
				dataByPlant: { combined: '', bfbof: '', eaf: '' },
				selected: filtering,
				groupType: value,
				compareCombine: '',
				global: global,
				collapse: 'daily',
				csvAllPlantTypes: false
			});
		}
	};

	getRequestParams() {
		const {
			global,
			dateFrom,
			dateTo,
			selected,
			selectedGrouping,
			selectedPlantType,
			collapse
		} = this.state;
		if (global) {
			return {
				grouping: 'regions',
				ids: '',
				collapse,
				plant: selectedPlantType,
				dateFrom: dateFrom.format(config.apiDateFormat),
				dateTo: dateTo.format(config.apiDateFormat)
			};
		} else if (selectedGrouping[selected]?.length > 0) {
			return {
				grouping:
					selected === 'regions' || selected === 'countries'
						? selected
						: 'groups',
				ids: selectedGrouping[selected]?.map(({ id }) => id).join(',') || '',
				collapse,
				plant: selectedPlantType,
				dateFrom: dateFrom.format(config.apiDateFormat),
				dateTo: dateTo.format(config.apiDateFormat)
			};
		}
	}
	// Run action creator to fetch smelter data based on filter form state
	filterData() {
		this.props.resetSteelSmelterCO2Emissions();
		const params = this.getRequestParams();
		if (params) {
			this.props.fetchSteelSmelterCO2Emissions(params);
		}
	}

	getChartType() {
		const { dateFrom, dateTo, showDispersion } = this.state;
		const days = dateTo.diff(dateFrom, 'd');
		const type = showDispersion ? 'a' : 'f';
		let size = '';

		if (days <= 50) {
			size = 'sm';
		} else if (days > 50 && days <= 100) {
			size = 'md';
		} else if (days > 100 && days <= 200) {
			size = 'lg';
		} else if (days > 200) {
			size = 'xl';
		} else if (days > 300) {
			size = 'xxl';
		}
		return { size, type };
	}

	// Build the card header element for display
	buildCardHeader() {
		const {
			compareCombine,
			global,
			groupType,
			plantTypes,
			collapse,
			selectedGrouping,
			selected
		} = this.state;

		const groupName = groupType.substring(6);
		let selection = groupType;
		if (groupType.startsWith('group_')) {
			selection = groupName;
		}

		let selections = 'groups';
		if (groupType === 'region') {
			selections = 'regions';
		} else if (groupType === 'country') {
			selections = 'countries';
		} else if (groupType.startsWith('group_')) {
			selections = `${groupName} ${selections}`;
		}

		const collapseTitle = collapse.charAt(0).toUpperCase() + collapse.slice(1);

		let title = '';
		if (global) {
			title = `${collapseTitle} CO2 Emissions: ${config.globalRegion.title}`;
		} else if (
			compareCombine === 'combine' &&
			selectedGrouping[selected]?.length > 1
		) {
			title = `${collapseTitle} CO2 Emissions: Combine multiple ${selections}`;
		} else if (
			compareCombine === 'compare' &&
			selectedGrouping[selected]?.length > 1
		) {
			title = `${collapseTitle} CO2 Emissions: Compare multiple ${selections}`;
		} else {
			title = `${collapseTitle} CO2 Emissions: Single ${selection}`;
		}
		title = `${title} ${plantTypes.length ? '-' : ''} ${plantTypes
			.map((pt) => (pt === 'eaf' ? 'EAF' : 'BF/BOF'))
			.join(', ')}`;
		return (
			<CardHeader>
				<i className="fa fa-line-chart" aria-hidden="true" /> {title}
			</CardHeader>
		);
	}

	buildRegionButton({ id, name, title }) {
		const { selectedGrouping, selected, isDownloadingAll } = this.state;

		return (
			<Fragment key={`region${name}`}>
				<Button
					id={`region_${id}`}
					outline
					size="sm"
					color="secondary"
					onClick={() => this.onRegionBtnClick({ name, val: id })}
					active={selectedGrouping[selected]?.map(({ id }) => id).includes(id)}
					disabled={isDownloadingAll}
					aria-label={title}
					className="light-active-border text-nowrap"
				>
					{title}
				</Button>
			</Fragment>
		);
	}

	buildPlantButton({ id, name, title }) {
		const {
			plantTypes,
			dataByPlant,
			selectedPlantType,
			selectedGrouping,
			selected,
			global,
			isDownloadingAll
		} = this.state;
		const key = name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
		return (
			<Fragment key={`plant${name}`}>
				<Button
					id={`plant_${id}`}
					outline
					size="sm"
					color="secondary"
					onClick={() => this.onPlantBtnClick({ key, id })}
					active={plantTypes.includes(key)}
					disabled={
						isDownloadingAll ||
						(((dataByPlant.combined === '' && plantTypes.length !== 1) ||
							(plantTypes.length === 1 &&
								dataByPlant[selectedPlantType] === '' &&
								key !== selectedPlantType)) &&
							(global || selectedGrouping[selected]?.length === 1))
					}
					aria-label={title}
					className="light-active-border"
				>
					{title}
				</Button>
			</Fragment>
		);
	}

	mapKeysToPeriodOfTime = (CO2EmissionsArr, periodAndSuffix) => {
		let [period, suffix] = periodAndSuffix.split('_');
		suffix = suffix ? `_${suffix}` : '';
		const keyMapper = { daily: 'd', weekly: 'w', monthly: 'm', annual: 'y' };

		const prefix = keyMapper[period];
		const isMapped = CO2EmissionsArr.some((record) =>
			record.hasOwnProperty(`${prefix}p${suffix}`)
		);
		if (!isMapped) {
			const mapped = CO2EmissionsArr.map(({ t, p }) => ({
				[`${prefix}t`]: this.getCsvTimeComponent(t, period),
				[`${prefix}p${suffix}`]: p
			}));
			return mapped;
		}
		return CO2EmissionsArr;
	};
	handleAllCO2EmissionsDataDownload = async () => {
		const {
			dateMin,
			dateMax,
			plantTypes,
			csvFull: { csvData },
			csvAllPlantTypes
		} = this.state;
		if (csvData.length) {
			this.csvFullDownloadLink.current.link.click();
			return;
		}
		const params = this.getRequestParams();
		this.setState({ isDownloadingAll: true });
		const CO2EmissionsData = await fetchCsvSteelCO2EmissionsData({
			...params,
			csvAllPlantTypes,
			plantTypes,
			dateFrom: dateMin,
			dateTo: dateMax
		});
		this.setState({ isDownloadingAll: false });
		const numberOfDiffKeysIteration = [
			...new Set(CO2EmissionsData.map((pd) => Object.keys(pd)[0]))
		].length;

		const mergedData = CO2EmissionsData.reduce((merged, curr, i) => {
			const key = Object.keys(curr)[0];

			const foundIndex = merged.findIndex(
				(item) => Object.keys(item)[0] === key
			);
			if (foundIndex >= 0) {
				const mergedCombinedIdx = [
					[
						...this.mapKeysToPeriodOfTime(
							merged[foundIndex][key]?.combinedIdx || [],
							key
						),
						...this.mapKeysToPeriodOfTime(curr[key]?.combinedIdx || [], key)
					]
				]
					.map((combine) =>
						key.startsWith('weekly') &&
						i > CO2EmissionsData.length - numberOfDiffKeysIteration - 1
							? getUniqueObjects(combine, 'wt')
							: combine
					)
					.flat();
				const mappedMergedSingleIdx = (
					merged[foundIndex][key]?.singleIdx || []
				).map(({ name, data }) => ({
					name,
					data: this.mapKeysToPeriodOfTime(data, key)
				}));
				const mappedCurrentSingleIdx = (curr[key]?.singleIdx || []).map(
					({ name, data }) => ({
						name,
						data: this.mapKeysToPeriodOfTime(data, key)
					})
				);

				const mergedSingleIdx = mergeSubArraysBasedOnKey(
					[...mappedMergedSingleIdx, ...mappedCurrentSingleIdx],
					'name'
				).map(({ name, data }) => ({
					name,
					data:
						key.startsWith('weekly') &&
						i > CO2EmissionsData.length - numberOfDiffKeysIteration - 1
							? getUniqueObjects(data, 'wt')
							: data
				}));

				merged[foundIndex][key] = {
					combinedIdx: mergedCombinedIdx,
					singleIdx: mergedSingleIdx
				};
			} else {
				merged = [...merged, { [key]: curr[key] }];
			}
			return merged;
		}, []);

		const combinedIdxs = mergedData
			.map((md) => Object.values(md).map(({ combinedIdx }) => combinedIdx))
			.flat();

		const zippedCombinedCO2Emissions = zipArrays(...combinedIdxs);

		const singleIdxs = groupByKey(
			mergedData
				.map((md) => Object.values(md).map(({ singleIdx }) => singleIdx))
				.flat()
				.flat(),
			'name'
		);
		const singleProdForZipping = Object.keys(singleIdxs).map((key) => ({
			name: key,
			singleIdx: Object.values(singleIdxs[key]).map(({ data }) =>
				Object.values(data)
			)
		}));

		const zippedSingleCO2Emissions = singleProdForZipping.map(
			({ name, singleIdx }) => ({ [name]: zipArrays(...singleIdx) })
		);
		this.setCsvHeadersAndData({
			combinedIdx: zippedCombinedCO2Emissions,
			singleIdx: zippedSingleCO2Emissions
		});
	};

	setCsvHeadersAndData({ combinedIdx = [], singleIdx = [] }) {
		const commonHeaders = [
			{ label: 'Date', key: 'dt' },
			{ label: 'Daily (kt)', key: 'dp' },
			{ label: 'Daily BF/BOF (kt)', key: 'dp_bfbof' },
			{ label: 'Daily EAF (kt)', key: 'dp_eaf' },
			{ label: 'Week', key: 'wt' },
			{ label: 'Weekly (kt)', key: 'wp' },
			{ label: 'Weekly BF/BOF (kt)', key: 'wp_bfbof' },
			{ label: 'Weekly EAF (kt)', key: 'wp_eaf' },
			{ label: 'Month', key: 'mt' },
			{ label: 'Monthly (kt)', key: 'mp' },
			{ label: 'Monthly BF/BOF (kt)', key: 'mp_bfbof' },
			{ label: 'Monthly EAF (kt)', key: 'mp_eaf' },
			{ label: 'Year', key: 'yt' },
			{ label: 'Annual (kt)', key: 'yp' },
			{ label: 'Annual BF/BOF (kt)', key: 'yp_bfbof' },
			{ label: 'Annual EAF (kt)', key: 'yp_eaf' }
		];

		let headers = [];
		//global
		if (combinedIdx.length && !singleIdx.length) {
			const keys = Object.keys(combinedIdx[0]);
			headers = commonHeaders
				.filter((header) => keys.some((key) => key === header.key))
				.map(({ label, key }) => {
					if (key !== 'dt' && key !== 'wt' && key !== 'mt' && key !== 'yt') {
						return { label: `Global ${label}`, key };
					}
					return { label, key };
				});
			this.setState(
				{ csvFull: { csvHeaders: headers, csvData: combinedIdx } },
				() => {
					// click the CSVLink component to trigger the CSV download
					this.csvFullDownloadLink.current.link.click();
					ReactGA.event({
						category: `steel_co2_emissions_data_all_download`,
						action: 'download',
						label: this.userSub
					});
					return true;
				}
			);
		}
		//single region/country/group
		else if (!combinedIdx.length && singleIdx.length) {
			const keys = Object.keys(Object.values(singleIdx[0])[0][0]);

			headers = commonHeaders
				.filter((header) => keys.some((key) => key === header.key))
				.map(({ label, key }) => {
					if (key !== 'dt' && key !== 'wt' && key !== 'mt' && key !== 'yt') {
						return { label: `${Object.keys(singleIdx[0])[0]} ${label}`, key };
					}
					return { label, key };
				});
			this.setState(
				{
					csvFull: {
						csvHeaders: headers,
						csvData: Object.values(singleIdx[0])[0]
					}
				},
				() => {
					// click the CSVLink component to trigger the CSV download
					this.csvFullDownloadLink.current.link.click();
					ReactGA.event({
						category: `steel_co2_emissions_data_all_download`,
						action: 'download',
						label: this.userSub
					});
					return true;
				}
			);
		}
		//multiple regions/countries/groups
		else if (combinedIdx.length && singleIdx.length) {
			const keys = Object.keys(combinedIdx[0]);
			let headers = commonHeaders
				.filter((header) => keys.some((key) => key === header.key))
				.map(({ label, key }) => {
					if (key !== 'dt' && key !== 'wt' && key !== 'mt' && key !== 'yt') {
						return { label: `Combined ${label}`, key };
					}
					return { label, key };
				});

			let csvData = combinedIdx;

			singleIdx.forEach((single, i) => {
				const singleHeaders = commonHeaders
					.filter((header) => keys.some((key) => key === header.key))
					.map(({ label, key }) => {
						if (key !== 'dt' && key !== 'wt' && key !== 'mt' && key !== 'yt') {
							return {
								label: `${Object.keys(singleIdx[i])[0]} ${label}`,
								key: `${key}_${i}`
							};
						}
						return { label, key: `${key}_${i}` };
					});
				headers = [...headers, ...singleHeaders];

				const dataForSingle = Object.values(single)[0].map((record) => {
					const values = Object.values(record);
					const keys = Object.keys(record);
					return keys.reduce(
						(o, k, j) => ({ ...o, [`${k}_${i}`]: values[j] }),
						{}
					);
				});
				csvData = mergeArrays(csvData, dataForSingle);
			});

			this.setState({ csvFull: { csvHeaders: headers, csvData } }, () => {
				// click the CSVLink component to trigger the CSV download
				this.csvFullDownloadLink.current.link.click();
				ReactGA.event({
					category: `steel_co2_emissions_data_all_download`,
					action: 'download',
					label: this.userSub
				});
				return true;
			});
		} else return;
	}

	buildCsvFullDownloadButton() {
		const {
			global,
			selected,
			selectedGrouping,
			canDownload,
			plantTypes,
			csvFull: { csvHeaders, csvData },
			csvAllPlantTypes
		} = this.state;

		let plantTitle = '';
		plantTypes.forEach((pt) => {
			plantTitle = plantTitle + pt + '-';
		});
		plantTitle = csvAllPlantTypes ? 'bfbof-eaf-' : plantTitle;
		let groupingTitle = '';
		if (global) {
			groupingTitle = 'global-';
		} else if (selectedGrouping[selected]?.length) {
			selectedGrouping[selected].forEach(({ name }) => {
				groupingTitle =
					groupingTitle + name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + '-';
			});
		} else return;
		const fileTitle = `${groupingTitle}${plantTitle}CO2Emissions`;

		if ((global || selectedGrouping[selected]?.length) && canDownload) {
			return (
				<Fragment>
					<LoaderButton
						className="btn mb-3 mr-1 btn-sm"
						color="success"
						disabled={false}
						type="button"
						isLoading={this.state.isDownloadingAll}
						onClick={this.handleAllCO2EmissionsDataDownload}
						text="Download all data"
						loadingText="Downloading"
						icon={
							!this.state.isDownloadingAll && (
								<i className="fa fa-download mr-2" aria-hidden="true" />
							)
						}
					/>
					<CSVLink
						data={csvData}
						filename={fileTitle}
						headers={csvHeaders}
						className="hidden"
						ref={this.csvFullDownloadLink}
						target="_blank"
					/>
				</Fragment>
			);
		}

		return (
			<Button
				id="buttonAllDownload"
				color="success"
				disabled
				className="mb-3 pull-right"
				size="sm"
			>
				<i className="fa fa-download mr-2" aria-hidden="true" /> Download all
				data
			</Button>
		);
	}

	buildCsvDownloadButton() {
		const { global, shortDateRange, selected, selectedGrouping, canDownload } =
			this.state;
		const { steelSmelterCO2Emissions } = this.props;

		if (
			(global || selectedGrouping[selected]?.length) &&
			!shortDateRange &&
			steelSmelterCO2Emissions.length !== 0 &&
			canDownload
		) {
			let { csvHeaders, csvData, csvFileName } = this.getCsvDataFromProps();

			return (
				<CSVLink
					id="buttonDownload"
					filename={csvFileName}
					headers={csvHeaders}
					data={csvData}
					className="btn btn-success mb-3 btn-sm btn-download"
					onClick={() => {
						ReactGA.event({
							category: `steel_co2_emissions_current_data_download`,
							action: 'download',
							label: this.userSub
						});
						return true;
					}}
				>
					<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
					data
				</CSVLink>
			);
		}

		return (
			<Button
				id="buttonDownload"
				color="success"
				disabled
				className="mb-3 pull-right"
				size="sm"
			>
				<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
				data
			</Button>
		);
	}

	resetCollapseButton = (collapse) => {
		const { selectedGrouping, selected, compareCombine } = this.state;

		this.setState({
			collapse,
			isCollapsing: true,
			compareCombine:
				selectedGrouping[selected]?.length > 1 ? compareCombine : '',
			plantTypes: [],
			selectedPlantType: '',
			dataByPlant: { combined: '', bfbof: '', eaf: '' }
		});
	};

	// Build the filter form
	buildFilterForm() {
		const { locations, groups, lastUploadDate } = this.props;
		const {
			dateMin,
			dateMax,
			dateFrom,
			dateTo,
			compareCombine,
			global,
			groupType,
			// regions,
			selectedGrouping,
			selected,
			collapse,
			isCollapsing,
			isDownloadingAll,
			plantTypes,
			dataByPlant,
			csvAllPlantTypes
		} = this.state;

		const groupName = groupType.substring(6);

		const { regions, countries } = locations;
		const sortedRegions = regions.sort((r1, r2) =>
			r1.name > r2.name ? 1 : -1
		);
		const regionToggleHidden = regions.length < 2;
		const regionAllCopy =
			selectedGrouping['regions']?.length === regions.length ? 'None' : 'All';

		return (
			<Form>
				<Container fluid className="px-0 container-width">
					<Row noGutters>
						<Col xs="12" sm="12">
							<Label for="groupType" className="mb-2 mr-3 align-top">
								<span className="sr-only">Geographical filtering type</span>
								<Input
									id="groupType"
									name="groupType"
									type="select"
									bsSize="sm"
									className="pointered"
									defaultValue={groupType}
									disabled={isDownloadingAll}
									onChange={this.onInputSelect}
								>
									{regions.length !== 1 && (
										<option value="region">Region filtering</option>
									)}
									<option value="country">Country filtering</option>
									{Object.keys(groups)
										.filter((g) => g !== 'plant')
										.map((g) => (
											<option key={`group_${g}`} value={`group_${g}`}>
												{g.charAt(0).toUpperCase() + g.slice(1)} filtering
											</option>
										))}
								</Input>
							</Label>
							{regions.length > 1 && (
								<Button
									id="regionGlobal"
									outline
									size="sm"
									color="secondary"
									active={global}
									disabled={isDownloadingAll}
									aria-label="global"
									className="mb-2 light-active-border"
									onClick={() => this.onGlobalBtnClick()}
								>
									Global
								</Button>
							)}
							{groupType === 'region' && regions.length > 0 && (
								<ButtonGroup className="mb-2 ml-3">
									{sortedRegions.map(({ name, id }) => {
										return this.buildRegionButton({
											id,
											name,
											title: name
										});
									})}
								</ButtonGroup>
							)}
							{groupType === 'region' && (
								<Button
									id="regionAll"
									color="primary"
									size="sm"
									onClick={() => this.onToggleAllRegions()}
									className="mb-2 ml-1"
									style={{ width: '54px' }}
									disabled={global || isDownloadingAll}
									hidden={regionToggleHidden}
								>
									{regionAllCopy}
								</Button>
							)}
							{groupType.startsWith('group_') &&
								groups[groupName].length > 0 &&
								groups[groupName].length <= 12 && (
									<ButtonGroup className="mb-2 ml-3">
										{groups[groupName].map(({ name, id }) => {
											return this.buildRegionButton({
												id,
												name,
												title: name
											});
										})}
									</ButtonGroup>
								)}
							{groupType.startsWith('group_') &&
								groups[groupName].length > 12 && (
									<MultiSelect
										options={groups[groupName]}
										values={selectedGrouping[groupType]}
										limit={config.multipleSelectionLimit}
										disabled={isDownloadingAll}
										onMultipleSelection={this.handleMultipleSelections}
									/>
								)}
							{groupType === 'country' && (
								<MultiSelect
									options={countries}
									values={selectedGrouping.countries}
									limit={config.multipleSelectionLimit}
									disabled={isDownloadingAll}
									onMultipleSelection={this.handleMultipleSelections}
								/>
							)}
							{(groupType === 'region' ||
								groupType === 'country' ||
								groupType.startsWith('group_')) && (
								<ButtonGroup className="mb-2 ml-3">
									{['BF/BOF', 'EAF'].map((name, i) => {
										return this.buildPlantButton({
											id: i,
											name,
											title: name
										});
									})}
								</ButtonGroup>
							)}
						</Col>
					</Row>
					<Row noGutters>
						<Col xs="12" sm="12">
							<DateRangePopover
								id="dateSelector"
								startDate={dateFrom}
								endDate={dateTo}
								minDate={dateMin}
								maxDate={dateMax}
								newestDate={lastUploadDate}
								shouldUpdateStatic={isCollapsing}
								isShortRange={collapse === 'annual'}
								disabled={isDownloadingAll}
								onDateRangeSelected={this.onDateRangeSelect.bind(this)}
							/>
							<ButtonGroup className="mb-3 mr-3 align-bottom">
								<Button
									outline
									size="sm"
									color="secondary"
									onClick={() => this.resetCollapseButton('daily')}
									active={collapse === 'daily'}
									disabled={isDownloadingAll}
									aria-label="Click to display daily CO2 Emissions"
								>
									Daily
								</Button>
								<Button
									outline
									size="sm"
									color="secondary"
									onClick={() => this.resetCollapseButton('weekly')}
									active={collapse === 'weekly'}
									disabled={isDownloadingAll}
									aria-label="Click to display weekly CO2 Emissions"
								>
									Weekly
								</Button>
								<Button
									outline
									size="sm"
									color="secondary"
									onClick={() => this.resetCollapseButton('monthly')}
									active={collapse === 'monthly'}
									disabled={isDownloadingAll}
									aria-label="Click to display monthly CO2 Emissions"
								>
									Monthly
								</Button>
								<Button
									outline
									size="sm"
									color="secondary"
									onClick={() => this.resetCollapseButton('annual')}
									active={collapse === 'annual'}
									disabled={isDownloadingAll}
									aria-label="Click to display yearly CO2 Emissions"
								>
									Annual
								</Button>
							</ButtonGroup>

							<ButtonGroup className="mb-3 mr-3 align-bottom">
								<Button
									id="buttonCombine"
									outline
									size="sm"
									color="secondary"
									onClick={() =>
										this.onRadioBtnClick({ compareCombine: 'combine' })
									}
									active={compareCombine === 'combine'}
									disabled={
										(plantTypes.length <= 1 &&
											(global || selectedGrouping[selected]?.length === 1)) ||
										(!global && selectedGrouping[selected]?.length < 1)
									}
									aria-label="Click to combine smelter data"
								>
									Combine
								</Button>
								<Button
									id="buttonCompare"
									outline
									size="sm"
									color="secondary"
									onClick={() =>
										this.onRadioBtnClick({ compareCombine: 'compare' })
									}
									active={compareCombine === 'compare'}
									disabled={
										// plantTypes.length <= 1 &&
										// (global || selectedGrouping[selected]?.length <= 1)
										(global || selectedGrouping[selected]?.length <= 1) &&
										(plantTypes.length <= 1 ||
											(plantTypes.length >= 1 &&
												plantTypes.some((pt) => !dataByPlant[pt]?.length)))
									}
									aria-label="Click to compare smelter data"
								>
									Compare
								</Button>
							</ButtonGroup>
							{this.state.canDownload && !isCollapsing && (
								<ButtonGroup>
									<Button
										id="toggleAllPlants"
										color={csvAllPlantTypes ? 'success' : 'secondary'}
										size="sm"
										className="mb-3"
										onClick={() => {
											this.setState({
												csvAllPlantTypes: !csvAllPlantTypes
											});
										}}
									>
										<i className="fa fa-industry" aria-hidden="true" />
										<span className="sr-only">Toggle plant types</span>
									</Button>
									<UncontrolledTooltip
										placement="top"
										delay={500}
										target="toggleAllPlants"
										trigger="click hover"
									>
										{csvAllPlantTypes
											? `Toggle to selected plant types`
											: `Toggle to
										all plant types`}
									</UncontrolledTooltip>
									<Fragment>{this.buildCsvFullDownloadButton()} </Fragment>
									<Fragment>{this.buildCsvDownloadButton()}</Fragment>
								</ButtonGroup>
							)}
						</Col>
					</Row>
				</Container>
			</Form>
		);
	}

	handleWorldSteelData = async () => {
		const { global, selectedGrouping, selected, collapse, dateFrom, dateTo } =
			this.state;
		let grouping = '';
		let id = '';
		if (!global && selectedGrouping[selected]?.length) {
			grouping =
				selected === 'regions' || selected === 'countries'
					? selected
					: 'groups';
			id = selectedGrouping[selected][0].id;
		}
		try {
			const data = await fetchWorldSteelData({
				grouping,
				id,
				collapse,
				dateFrom: dateFrom.format(config.apiDateFormat),
				dateTo: dateTo.format(config.apiDateFormat)
			});
			if (data.length === 0) {
				toastr.warning(
					`No ${collapse} World Steel Data found for ${selectedGrouping[selected][0].name} `
				);
			}
		} catch (e) {
			console.log(e);
			const message = e?.response?.data?.message || e;
			toastr.error(`World Steel Data: ${message}`);
		}
	};

	getCsvTimeComponent(t, collapse = '') {
		const dateFormatting = collapse ? collapse : this.state.collapse;

		if (dateFormatting === 'weekly') {
			return `W${moment.utc(t, 'WW GGGG').format('WW GGGG')}`;
		} else if (dateFormatting === 'monthly') {
			return `${moment.utc(t, 'MMM YYYY').format('MMM YYYY')}`;
		} else if (dateFormatting === 'annual') {
			return `${moment.utc(t, 'YYYY').format('YYYY')}`;
		} else return moment.utc(t).format(config.dspDateFormat);
	}

	// Build csv data from the props for the download feature
	getCsvDataFromProps() {
		const {
			steelSmelterCO2Emissions: { singleIdx = [], combinedIdx = [] }
		} = this.props;
		let {
			dateFrom,
			dateTo,
			global,
			dataByPlant,
			plantTypes,
			selectedGrouping,
			selected,
			collapse
		} = this.state;

		let plantTitle = '';
		plantTypes.forEach((pt) => {
			plantTitle = plantTitle + pt + '-';
		});
		let groupingTitle = '';
		if (global) {
			groupingTitle = 'global-';
		} else {
			selectedGrouping[selected].forEach(({ name }) => {
				groupingTitle =
					groupingTitle + name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + '-';
			});
		}
		const fileTitle = `${groupingTitle}${plantTitle}${collapse}-`;
		// Initialise our csv data
		let csvFileName = `${fileTitle}CO2Emissions-${dateFrom.format(
			config.apiDateFormat
		)}-${dateTo.format(config.apiDateFormat)}.csv`;
		let timeLabel = 'Date';
		if (collapse === 'weekly') {
			timeLabel = 'Week';
		} else if (collapse === 'monthly') {
			timeLabel = 'Month';
		} else if (collapse === 'annual') {
			timeLabel = 'Year';
		}
		let csvHeaders = [{ label: timeLabel, key: 't' }];
		let csvData = [];

		if (global || selectedGrouping[selected]?.length === 1) {
			const plantKeys = Object.keys(dataByPlant).filter(
				(pk) => pk !== 'combined'
			);

			plantKeys.forEach(
				(pk) =>
					plantTypes.includes(pk) &&
					dataByPlant[pk]?.length &&
					dataByPlant[pk].forEach(({ t, p }, j) => {
						// Add the time column value to the csv data if this is the first region iteration
						// or if we've somehow got a frame error from the api data and the row is missing
						if (!csvData[j]) {
							csvData[j] = {
								t: this.getCsvTimeComponent(t)
							};
						}
						// Generate the region-based f and σ column names
						const title = pk === 'eaf' ? 'EAF' : 'BF/BOF';
						let namepkey = `${title} CO2 Emissions (kt)`;

						// Add the column names to the csv headers if this is the first data iteration
						if (j === 0) {
							csvHeaders = [
								...csvHeaders,
								...[
									{
										label: namepkey,
										key: namepkey
									}
								]
							];
						}

						// Set the fp column on the csv data
						csvData[j][namepkey] = p;
					})
			);

			dataByPlant.combined?.length &&
				dataByPlant.combined.forEach(({ t, p }, k) => {
					// Add the time column value to the csv data if this is the first iteration
					// or if we've somehow got a frame error from the api data and the row is missing
					if (!csvData[k]) {
						csvData[k] = {
							t: this.getCsvTimeComponent(t)
						};
					}
					// Generate the p column names
					let namepkey = `CO2 Emissions (kt)`;
					// let nameakey = `Activity`;
					// Add the column names to the csv headers if this is the first data iteration
					if (k === 0) {
						csvHeaders = [
							...csvHeaders,
							...[
								{
									label: namepkey,
									key: namepkey
								}
							]
						];
					}
					// Set the f column on the csv data
					csvData[k][namepkey] = p;
					// csvData[k][nameakey] = a;
				});
		} else {
			singleIdx.forEach(({ name, data }) => {
				data.forEach(({ t, p }, j) => {
					// Add the time column value to the csv data if this is the first region iteration
					// or if we've somehow got a frame error from the api data and the row is missing
					if (!csvData[j]) {
						csvData[j] = {
							t: this.getCsvTimeComponent(t)
						};
					}

					// Generate the region-based f and σ column names
					let namepkey = `${name} CO2 Emissions (kt)`;

					// Add the column names to the csv headers if this is the first data iteration
					if (j === 0) {
						csvHeaders = [
							...csvHeaders,
							...[
								{
									label: namepkey,
									key: namepkey
								}
							]
						];
					}

					// Set the p column on the csv data
					csvData[j][namepkey] = p;
				});
			});
			combinedIdx.forEach(({ t, p }, k) => {
				// Add the time column value to the csv data if this is the first iteration
				// or if we've somehow got a frame error from the api data and the row is missing
				if (!csvData[k]) {
					csvData[k] = {
						t: this.getCsvTimeComponent(t)
					};
				}

				// Generate the p column names
				let namepkey = global
					? `Global CO2 Emissions (kt)`
					: `Combined CO2 Emissions (kt)`;

				// Add the column names to the csv headers if this is the first data iteration
				if (k === 0) {
					csvHeaders = [
						...csvHeaders,
						...[
							{
								label: namepkey,
								key: namepkey
							}
						]
					];
				}
				// Set the f column on the csv data
				csvData[k][namepkey] = p;
			});
		}

		return {
			csvFileName,
			csvHeaders,
			csvData
		};
	}

	buildRegionSelectError() {
		return (
			<Alert color="warning" className="p-2 mb-1">
				Please select at least one region.
			</Alert>
		);
	}

	buildShortDateRangeError() {
		return (
			<Alert color="warning" className="p-2 mb-1">
				Please select a date range of at least 10 days.
			</Alert>
		);
	}

	buildLoadingMessage() {
		return (
			<Fragment>
				<div className="h6 mb-3">Chart data loading...</div>
				<Progress animated value="100" />
			</Fragment>
		);
	}

	getSelectedDetail(arr = []) {
		const { global, selected, selectedGrouping, plantTypes, compareCombine } =
			this.state;
		if (arr.length === 1 && global && plantTypes.length !== 1) {
			return [{ key: 'global', ...config.globalRegion }];
		} else if (arr.length === 1 && arr[0] === 'combine') {
			return [{ key: 'combine', ...config.combinedRegion }];
		} else if (
			(plantTypes.length > 1 && compareCombine === 'compare') ||
			(plantTypes.length === 1 &&
				(selectedGrouping[selected]?.length === 1 || global))
		) {
			return arr.map((a) =>
				a === 'bfbof'
					? { key: 'bfbof', title: 'BF/BOF', name: 'BF/BOF', colour: '#36454F' }
					: { key: 'eaf', title: 'EAF', name: 'EAF', colour: '#FF5733' }
			);
		} else if (arr.length >= 1) {
			return arr.map((a) => ({
				key: a.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
				title: a,
				name: a,
				colour:
					selectedGrouping[selected].find(({ name }) => name === a)?.colour ||
					''
			}));
		}
	}

	getChartData(dataArr) {
		const details = this.getSelectedDetail(dataArr.map(({ name }) => name));

		// Build the datasets by iterating the allRegions array and reducing it with the smelterData compare field,
		// so that the original order of the regions is maintained
		return dataArr.map(({ name, data }) => {
			const { collapse, dateMax } = this.state;
			const detail = details.find(
				(d) => d.key === name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
			);

			//filled point for combine
			const fCombinePointBackgroundColour =
				name === 'combine' ? { pointBackgroundColor: detail.colour } : {};

			//fStyle will be added to existing active CO2 Emissions style in configuration
			const fStyle = {
				label: detail.name,
				borderColor: detail.colour,
				...fCombinePointBackgroundColour
			};

			const addStyle = fStyle;

			return {
				data: data
					.map(({ t, p }) => ({
						t: ['weekly', 'monthly', 'annual'].includes(collapse)
							? t
							: moment.utc(t).format(config.dspDateFormat),
						y: p
					}))
					.filter(({ t }, i) =>
						// exclude the last point
						{
							if (
								data.length > 2 &&
								i === data.length - 1 &&
								((collapse === 'weekly' &&
									moment(dateMax).isBefore(
										moment
											.utc(t, 'WW GGGG')
											.endOf('isoWeek')
											.format(config.dspDateFormat)
									)) ||
									(collapse === 'monthly' &&
										dateMax.isBefore(
											moment
												.utc(t, 'MMM YYYY')
												.endOf('month')
												.format(config.dspDateFormat)
										)) ||
									(collapse === 'annual' &&
										moment(dateMax).isBefore(
											moment
												.utc(t, 'YYYY')
												.endOf('year')
												.format(config.dspDateFormat)
										)))
							) {
								return false;
							}
							return true;
						}
					),
				...genChartStyle(this.getChartType()),
				...addStyle
			};
		});
	}

	// Individual data lines shown for multiple regions
	buildLineChart() {
		const { steelSmelterCO2Emissions } = this.props;

		const {
			compareCombine,
			global,
			selected,
			selectedGrouping,
			plantTypes,
			dataByPlant,
			collapse,
			isCollapsing
		} = this.state;
		const isCombined =
			compareCombine === 'combine' &&
			!global &&
			selectedGrouping[selected]?.length > 1;

		const isCombinedPlants =
			(global || selectedGrouping[selected]?.length === 1) &&
			plantTypes.length !== 1 &&
			compareCombine !== 'compare';

		let apiData = '';

		if (isCombined) {
			apiData = [
				{
					name: 'combine',
					data:
						steelSmelterCO2Emissions.combinedIdx ||
						(steelSmelterCO2Emissions?.singleIdx &&
							steelSmelterCO2Emissions.singleIdx[0]?.data) ||
						''
				}
			];
		} else if (isCombinedPlants && global) {
			apiData = [
				{
					name: 'Global',
					data: dataByPlant.combined
				}
			];
		} else if (isCombinedPlants && selectedGrouping[selected]?.length === 1) {
			apiData = [
				{
					name: selectedGrouping[selected][0].name,
					data: dataByPlant.combined
				}
			];
		} else if (
			!isCombinedPlants &&
			(global || selectedGrouping[selected]?.length === 1)
		) {
			apiData = plantTypes.map((name) => ({ name, data: dataByPlant[name] }));
		} else {
			apiData =
				steelSmelterCO2Emissions.singleIdx ||
				selectedGrouping[selected]?.map(({ name }) => ({ name, data: '' }));
		}

		//compare data length gives info whether API response is received, combined data length doesn't contain that information
		if (!apiData || apiData.every(({ data }) => data === '' || isCollapsing)) {
			return this.buildLoadingMessage();
		}

		//check this condition
		if (apiData.every(({ data }) => data.length === 0)) {
			return;
		}

		const datasets = this.getChartData(apiData);

		const labels = ['weekly', 'monthly', 'annual'].includes(collapse)
			? datasets[0].data.map(({ t }) => t)
			: [];

		let options = {};
		if (collapse === 'weekly') {
			options = ewChartOptions;
		} else if (collapse === 'monthly') {
			options = emChartOptions;
		} else if (collapse === 'annual') {
			options = eyChartOptions;
		} else {
			options = eChartOptions;
		}
		return (
			<Card className="mb-3">
				{this.buildCardHeader()}
				<CardBody>
					<Container
						fluid
						className="pl-0 container-width"
						style={{ paddingRight: '21px' }}
					>
						<Row noGutters className="mt-0">
							<Col xs="12" sm="12">
								<Line data={{ labels, datasets }} options={options} redraw />
							</Col>
						</Row>
					</Container>
				</CardBody>
			</Card>
		);
	}

	render() {
		let { global, shortDateRange, selectedGrouping, selected } = this.state;

		return (
			<DocumentTitle title="GAINS | Steel Smelter CO2 Emissions">
				<div className="content-wrapper">
					<Container fluid>
						{/* Help, crumbs and the filter form */}
						{/* <div className="pull-right">{smeltersInlineHelpContent}</div> */}
						<Crumbs type="steel" path={[{ title: 'Smelter CO2 Emissions' }]} />
						{this.buildFilterForm()}

						{/* Error messages */}
						{shortDateRange && this.buildShortDateRangeError()}
						{!shortDateRange &&
							!global &&
							!selectedGrouping[selected]?.length &&
							this.buildRegionSelectError()}

						{/* Different chart types */}
						{!shortDateRange &&
							(selectedGrouping[selected]?.length > 0 || global) &&
							this.buildLineChart()}
					</Container>
				</div>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({
	lastUploadDate,
	locations,
	groups,
	steelSmelterCO2Emissions,
	steelSmelterUpdates
}) => {
	const { regions } = locations;
	const filteredGroups =
		regions.length > 1
			? groups
			: Object.keys(groups)
					.filter((objKey) => objKey !== 'Custom region')
					.reduce((newObj, key) => {
						newObj[key] = groups[key];
						return newObj;
					}, {});
	return {
		lastUploadDate,
		locations,
		groups: filteredGroups,
		steelSmelterCO2Emissions: steelSmelterCO2Emissions.data,
		steelSmelterUpdates
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchLastUploadDate: (filterData) =>
		dispatch(fetchLastUploadDate(filterData)),
	resetLastUploadDate: () => dispatch(resetLastUploadDate()),
	fetchSteelSmelterCO2Emissions: (filterData) =>
		dispatch(fetchSteelSmelterCO2Emissions(filterData)),
	resetSteelSmelterCO2Emissions: () =>
		dispatch(resetSteelSmelterCO2Emissions()),

	fetchLocations: (filterData) => dispatch(fetchLocations(filterData)),
	resetLocations: () => dispatch(resetLocations()),

	fetchGroups: (filterData) => dispatch(fetchGroups(filterData)),
	resetGroups: () => dispatch(resetGroups())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(SteelSmeltersCO2Emissions));
